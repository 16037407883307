<template lang="pug">
main.about-page
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/img/real_site_images/resized/deluxe_twin room.jpg')",headline="More than just a room")

	.container
		left-side-section(
			title="Rooms"
			:img='require("@/assets/img/real_site_images/resized/deluxe_twin room.jpg")'
			description=""
		)
			| Deluxe Suite - 2<br />
			| Junior Suite - 2<br />
			| Deluxe Room - 36<br />
			| The Villa Rooms - 40 <br />

		right-side-section(
			title="Accommodation"
			:img='require("@/assets/img/real_site_images/resized/corridor2.jpg")'
			description=" Blending chic and modern sophistication, Time Square’s Accommodation reflect the art of modernity and design, with all the comforts of home along with the best technology. Almost all the rooms offer a captivating sight of Time Square’s interior along with its frescos and precious mosaic floors. All the accommodations provide the best exclusive services and many more."
		)

		left-side-section(
			title="Features"
			:img='require("@/assets/img/final_3d_images/low/img001.jpg")'
			description=""
		)
			| Free High Speed Wi-Fi <br />
			| Centrally Air-Conditioning <br />
			| Laptop Size Safe <br />
			| Flat Screen Satellite TV <br />
			| Large Wardrobe <br />
			| Working Desk <br />
			| 24 Hour Butler Service <br />
			| Daily Newspaper Service <br />
			| View : Poolside <br />
			| Hypoallergenic and Anatomic Pillows <br />
			| Fully stocked and daily-refurbished minibar <br />
			| Coffee and Tea making facilities <br />
			| In-room Spa services <br />
			| Full equipped Large Bathrooms with <pre style="font:inherit;display:inline-block;">Rain-shower</pre>

		full-image-section(:img='require("@/assets/img/final_3d_images/low/img004.jpg")',alt="Spa Overview")
</template>

<script>
import DisplayHeader from '../components/DisplayHeader.vue'
import RightSideSection from '../components/XRightSideSection.vue'
import AreaSection from '../components/XAreaSection.vue'
import LeftSideSection from '../components/XLeftSideSection.vue'
import FullImageSection from '../components/XFullImageSection.vue'

export default {
	components: {
		DisplayHeader,
	RightSideSection,
	LeftSideSection,
	AreaSection,
	FullImageSection
  },
}
</script>

<style>
</style>